export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (from.query.gclid && !to.query.gclid) {
      if (from.path === to.path) {
        next(false);
      } else {
        next({
          path: to.path,
          query: { ...to.query, gclid: from.query.gclid },
        });
      }
    } else {
      next();
    }
  });
};
