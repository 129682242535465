import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export function createRouter(ssrContext, createDefaultRouter, routerOptions) {
  const options = routerOptions
    ? routerOptions
    : createDefaultRouter(ssrContext).options;
  const hostname = ssrContext ? ssrContext.req.headers.host : location.host;
  const path = ssrContext ? ssrContext.url : location.pathname;

  return new Router({
    ...options,
    routes: fixRoutes(options.routes, { hostname, path }),
  });
}

function fixRoutes(defaultRoutes, { hostname, path }) {
  return defaultRoutes.reduce((routes, route) => {
    if (route.name !== 'prismic-preview') {
      // We have two modes
      // pro_subdomain is defined => we rely on it to segrate pro and part
      // otherwise, it will mimic a subdomain by creating a /pro first level in the url path
      // this will make it compliant with Vercel previews and URLs that doesn't handle subdmonains
      const isPro =
        hostname.indexOf(process.env.pro_subdomain) === 0 ||
        path.indexOf('/pro') === 0;
      if (isPro) {
        routes.push({
          ...route,
          name: isPro ? `pro__${route.name}` : `part__${route.name}`,
          path: !process.env.pro_subdomain ? `/pro${route.path}` : route.path,
          props: {
            isPro: true,
          },
        });
      } else {
        routes.push({
          ...route,
          name: `part__${route.name}`,
          props: {
            isPro: false,
          },
        });
      }
      return routes;
    }
    routes.push(route);
    return routes;
  }, []);
}
