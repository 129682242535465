import { productThumb } from '@/utils/adapters/product';
import getContact from '@/utils/adapters/contact';
import getContactList from '@/utils/adapters/contactList';

function getEntry(
  { data, entryLink, entryLinkTitle },
  i18n,
  $enhancedLinkSerializer
) {
  // Buttons
  const buttons = [];

  if (data.nav_ctas.length) {
    data.nav_ctas.forEach((cta) => {
      const ctaLink = $enhancedLinkSerializer(cta.nav_cta_link);
      if (ctaLink) {
        buttons.push({
          label: cta.nav_cta_label,
          cta: {
            ...ctaLink,
            variant: cta.nav_cta_variant,
          },
        });
      }
    });
  }

  // Contact
  const singleContact = data?.contact?.data;
  let contact, contactCta, contactList;

  if (singleContact) {
    contact = getContact(singleContact);
    contact.icon = 'control-call';
    contact.subhead = null;
    contactCta = {
      link: $enhancedLinkSerializer(data.contact_cta_link),
      label: data.contact_cta_label,
    };

    if (data.contact_title) {
      contact.title = data.contact_title;
    }
  } else if (data?.contact_list?.length) {
    contactList = data ? getContactList(data, $enhancedLinkSerializer) : null;
  }

  // Product cards
  const cards = [];

  if (data.nav_products.length) {
    data.nav_products.forEach(({ product }) => {
      if (product) {
        const productData = productThumb(product, $enhancedLinkSerializer);
        if (productData) {
          cards.push({
            type: 'product-thumb',
            data: {
              ...productData,
              heading: 'p',
            },
          });
        }
      }
    });
  }

  // Total
  let title;
  let seeAll;

  if (entryLink) {
    seeAll = {
      link: entryLink,
      label: i18n.t('header.seeAll', [entryLinkTitle]),
    };
  }

  title = i18n.tc('header.title', data.nav_products.length, {
    count: data.nav_products.length,
  });

  return {
    asideTitle: data.nav_aside_title,
    buttons: buttons.length ? buttons : [],
    contact,
    contactCta,
    contactList,
    cards,
    seeAll,
    title,
  };
}

function navSanitizer(
  { app: { i18n }, route, $enhancedLinkSerializer },
  { data: { body: data = [] }, modules }
) {
  const navSlices = ['nav_item', 'nav_tabs'];
  return data
    .filter((slice) => navSlices.includes(slice.slice_type))
    .map((sliceData) => {
      const { slice_type, primary, items = [] } = sliceData;
      let content = {};

      const label = primary.nav_entry_label;
      const uid = primary?.nav_entry_link?.uid;
      const freespeeParameter =
        primary?.nav_entry_link?.data?.freespee_parameter;
      const routeUid = route?.params?.market;
      const current = uid && uid === routeUid;

      // Link
      const entryLinkTitle = primary?.nav_entry_link?.data?.title;
      const entryLink = $enhancedLinkSerializer(primary.nav_entry_link);

      // Simple panel
      if (slice_type === 'nav_item') {
        const id = primary?.navigation_module?.id;
        const navData = modules[id];

        content = {
          type: 'category',
          data: getEntry(
            { ...navData, entryLink, entryLinkTitle },
            i18n,
            $enhancedLinkSerializer
          ),
        };
      }
      // Panel with tabs
      else if (slice_type === 'nav_tabs') {
        let seeAll;

        // See all link
        if (entryLink) {
          seeAll = {
            link: entryLink,
            label: i18n.t('header.seeAll', [entryLinkTitle]),
          };
        }

        const tabs = [];
        items.forEach((item) => {
          const id = item?.navigation_module?.id;
          const navData = modules[id];
          if (!item.tab_label || !navData) {
            return;
          }
          tabs.push({
            name: item.tab_label,
            content: getEntry({ ...navData }, i18n, $enhancedLinkSerializer),
          });
        });

        content = {
          type: 'tabs',
          seeAll,
          data: tabs,
        };
      }

      return {
        uid,
        current,
        label,
        content,
        freespeeParameter,
      };
    });
}

export default navSanitizer;
