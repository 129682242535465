export default function (req) {
  let baseUrl;
  let url;

  // server side
  // `context.req` is only available on server
  if (req) {
    baseUrl = `https://${req.headers['x-forwarded-host'] || req.headers.host}`;
    url = new URL(req.url, baseUrl).href;
  }

  // client side
  else {
    url = window.location.href;
    baseUrl = window.location.origin;
  }

  return {
    baseUrl,
    url,
  };
}
