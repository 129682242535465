/**
 * Function to get age with date
 * https://stackoverflow.com/a/7091965
 */
export default (date) => {
  const today = new Date();
  const birthDate = convertFromStringToDate(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate()))
    age--;
  return age;
};

/**
 * Convert string fr-FR to date en-Us
 */
const convertFromStringToDate = (date) => {
  const dateArray = date.split('/');
  const newDateFormat = `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`;
  return new Date(newDateFormat);
};
