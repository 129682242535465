import navSanitizer from './nav-sanitizer';
import findContactSanitizer from '~/components/find-contact-push/adapter';

export default (content, context) => {
  const { data } = content;
  const {
    app: { i18n },
    $enhancedLinkSerializer,
  } = context;

  const privateLink = $enhancedLinkSerializer(data.link_private);
  const proLink = $enhancedLinkSerializer(data.link_professional);

  // Switcher
  const switcher = [
    {
      label: i18n.t('header.switcher.private'),
      cta: privateLink,
      theme: 'primary',
      current: !data.is_pro,
    },
    {
      label: i18n.t('header.switcher.professional'),
      cta: proLink,
      theme: 'secondary',
      current: data.is_pro,
    },
  ];

  // Action CTAs
  const actions = data.banner_actions.map((item) => {
    return {
      mobileVisibility: item.action_mobile_visibility,
      link: $enhancedLinkSerializer(item.action_link),
      label: item.action_label,
      icon: item.action_icon,
    };
  });

  // Navigation
  const nav = navSanitizer(context, content);

  // Find contact search module
  const findContactSlice = data.body.find(
    (slice) => slice.slice_type === 'find_contact_push'
  );
  const findContact = findContactSlice
    ? findContactSanitizer(findContactSlice, null, context)
    : null;

  return {
    rootLink: data.is_pro ? proLink : privateLink,
    switcher,
    menuTitle: data.menu_title,
    nav,
    actions,
    findContact,
  };
};
