import getUrl from './get-url';

export const PRO_TAG_NAME = 'pro';
export const PART_TAG_NAME = 'particulier';

// Get document type based on its tags
export const getDocumentType = (tags) => {
  switch (true) {
    case tags.indexOf(PRO_TAG_NAME) !== -1:
      return PRO_TAG_NAME;
    case tags.indexOf(PART_TAG_NAME) !== -1:
      return PART_TAG_NAME;
    default: {
      console.warn(
        'No tag specified, defaulting to particulier type of document'
      );
      return PART_TAG_NAME;
    }
  }
};

// Get site type based on URL
export const getSiteType = (req) => {
  const { baseUrl, url } = getUrl(req);
  const regex = `^http(s)?://${process.env.pro_subdomain}.`;
  return baseUrl.match(new RegExp(regex, 'g')) !== null ||
    url.match(/\/pro(\/.*)?$/g) !== null
    ? PRO_TAG_NAME
    : PART_TAG_NAME;
};
