
export default {
  props: {
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
