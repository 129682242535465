import linkResolver from './link-resolver';

const journeyLinks = [
  'tarif-complementaire-sante.april.fr',
  'april.fr/tarif-assurance-pret-immobilier',
];

const isJourneyLink = (url) =>
  journeyLinks.some((journeyLink) => url.includes(journeyLink));

export default (link, isPro, isDocument, route, store) => {
  const redirections = store?.state?.redirections || [];

  if (!link) {
    process.env.NODE_ENV !== 'production' &&
      console.warn(
        "Missing Link. Please check you didn't mispell a field name"
      );
    return null;
  }

  if (!link.tags) {
    link.tags = [];
  }

  if (
    isPro &&
    link.tags.indexOf('pro') === -1 &&
    link.tags.indexOf('particulier') === -1
  ) {
    link.tags.push('pro');
  }

  link.sitePro = isPro;

  if (link.link_type === 'Document' || isDocument) {
    const redirection = redirections.find(
      (redirectionLink) => redirectionLink.from === linkResolver(link)
    );
    const isExternalRedirection = redirection?.to?.includes('https')
      ? 'true'
      : 'false';
    return redirection
      ? {
          href: redirection.to,
          external: isExternalRedirection,
        }
      : { field: { ...link } };
  } else if (link.link_type === 'Web') {
    let href = link.url;

    // if link is a JourneyLink forward gclid param
    if (isJourneyLink(link.url)) {
      const gclid = route.query.gclid;
      const url = new URL(href);
      if (gclid) url.searchParams.append('gclid', gclid);
      href = url.toString();
    }
    return {
      href,
      external: link.target === '_blank' ? 'blank' : '',
    };
  } else if (
    ['application/pdf', 'application/postscript', 'application/zip'].includes(
      link.type || link.mime_type
    )
  ) {
    if (link.mime_type === 'application/postscript') {
      const url = new URL(link.url);
      url.search = '';
      link.url = url.toString();
    }

    return {
      href: link.url,
      external: 'blank',
    };
  } else if (
    link.link_type === 'Any' ||
    link.link_type === null ||
    link.link_type === undefined
  ) {
    return null;
  }
};
