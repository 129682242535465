const camelize = /-(\w)/g;

export const toPascalCase = function (str) {
  if (!str) {
    return '';
  }
  str = toCamelCase(str);
  return str[0].toUpperCase() + str.slice(1);
};

export const toCamelCase = function (str) {
  if (!str) {
    return '';
  }
  return str.replace(/_/g, '-').replace(camelize, (_, c) => {
    return c ? c.toUpperCase() : '';
  });
};
