
import LazyHydrate from 'vue-lazy-hydration';
import { isProRoute } from '~/utils/helpers/data';
import { pushPageView } from '~/plugins/gtm';
import {
  LAYOUT_SET_PRO,
  LAYOUT_SET_HEADER_CURRENT,
} from '~/store/mutation-types';
import FreespeePageTracking from '~/mixins/freespee-page-tracking';

export default {
  name: 'AppLayout',
  components: {
    LazyHydrate,
    Header: () => import('~/components/header'),
    SmartButton: () => import('~/components/smart-button'),
    FixedButton: () => import('~/components/fixed-button'),
  },
  mixins: [FreespeePageTracking],
  head() {
    const locale = this.$i18n.locales.find(
      (loc) => loc.code === this.$i18n.locale
    );
    return {
      htmlAttrs: {
        lang: locale.code,
      },
      bodyAttrs: {
        class: 'theme-blue',
      },
    };
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    isPro() {
      return this.$store.state.layout.isPro;
    },
    ekomi() {
      return this.$store.state.ekomi;
    },
    hasIadvizeWidget() {
      return this.$store.state.layout.iAdvizeIsActive;
    },
  },
  watch: {
    $route(to) {
      pushPageView(this);
      const isPro = isProRoute(to);
      this.$store.commit(`layout/${LAYOUT_SET_PRO}`, isPro);
      const marketUid = to?.params?.market;
      this.$store.commit(`layout/${LAYOUT_SET_HEADER_CURRENT}`, marketUid);
      this.handleFreespeePageTracking();
    },
  },
  mounted() {
    window.__fs_conf = window.__fs_conf || [];
  },
  methods: {
    openCookies() {
      window._axcb.push(function (sdk) {
        const firstInput = sdk.overlayNode.querySelector('input');
        !firstInput && sdk.openCookies();
      });
    },
  },
};
