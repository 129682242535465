import { routerLinks, contactLinks } from '@/utils/fetch-links';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';

function parseActions(actions = [], context) {
  const { $enhancedLinkSerializer } = context;

  return actions.reduce((acc, entry) => {
    const { slice_type, primary } = entry;

    if (slice_type.match(/search_module/gi)) {
      acc.push({
        type: slice_type,
        mobileVisibility: primary.display_on_mobile,
      });
      return acc;
    }

    acc.push({
      type: slice_type,
      mobileVisibility: primary?.display_on_mobile ?? false,
      label: primary?.label ?? null,
      link: $enhancedLinkSerializer(primary.link),
      icon: primary.icon,
    });

    return acc;
  }, []);
}

function parseSubmenuItems(entries, context) {
  const { $enhancedLinkSerializer } = context;

  const groups = entries.reduce((acc, curr) => {
    const groupName = curr.group_name ?? 'nogroup';
    acc[groupName] = acc[groupName] ?? [];

    acc[groupName].push({
      label: curr.label,
      link: $enhancedLinkSerializer(curr.link),
    });

    return acc;
  }, {});

  const items = Object.entries(groups).map(([key, values]) => {
    return {
      title: key === 'nogroup' ? '' : key,
      items: values,
    };
  });

  return items;
}

function uid() {
  return Math.random().toString(16).slice(2);
}

function parseMenu(data, type, context) {
  const {
    app: { i18n },
    $enhancedLinkSerializer,
  } = context;

  const isPro = type === 'pro';
  const entries = data?.[isPro ? 'proMenu' : 'privateMenu'];
  const link = $enhancedLinkSerializer(
    data?.[isPro ? 'pro_link' : 'private_link']
  );

  const title = i18n.t(
    isPro ? 'header.switcher.professional' : 'header.switcher.private'
  );

  const icon = isPro ? 'building' : 'people';

  const items = entries
    .map((entry) => {
      const { primary } = entry;

      const cardMenu =
        primary.card_menu_title &&
        primary.card_menu_link &&
        primary.card_menu_link_label &&
        primary.card_menu_market &&
        primary.card_menu_image
          ? {
              title: primary.card_menu_title,
              link: $enhancedLinkSerializer(primary.card_menu_link),
              linkLabel: primary.card_menu_link_label,
              market: primary.card_menu_market,
              image: imageAdapter(
                primary.card_menu_image,
                primary.card_menu_image.name
              ),
            }
          : null;

      return {
        id: `${type}-${uid()}${primary.link?.id}`,
        label: primary.label,
        cardMenu: cardMenu,
        link: $enhancedLinkSerializer(primary.link),
        items: parseSubmenuItems(entry.items, context),
        footer: {
          contact_info: !!primary.contact_info?.data && {
            label: primary.contact_info?.data.contact_phone_label ?? null,
            phone: primary.contact_info?.data.contact_phone_number ?? null,
            description:
              primary.contact_info?.data.contact_inline_description ?? null,
            mention: primary.contact_info?.data.contact_mention ?? null,
          },
          cta1: primary.cta_1_label &&
            primary.cta_1_link && {
              label: primary.cta_1_label ?? null,
              link: $enhancedLinkSerializer(primary.cta_1_link),
            },
          cta2: primary.cta_2_label &&
            primary.cta_2_link && {
              label: primary.cta_2_label ?? null,
              link: $enhancedLinkSerializer(primary.cta_2_link),
            },
        },
      };
    })
    .filter(Boolean);

  return { type, title, icon, link, items };
}

function parseFooterLinks(links, context) {
  const { $enhancedLinkSerializer } = context;

  return links.map((entry) => {
    return {
      icon: entry.icon,
      label: entry.label,
      link: $enhancedLinkSerializer(entry.link),
    };
  });
}

async function getHeader($prismic, context, isPro) {
  const res = await $prismic.api.getSingle('module_header_v2', {
    fetchLinks: [
      ...routerLinks,
      ...contactLinks,
      'masterpage.title',
      'masterpage.freespee_parameter',
    ],
  });

  if (!res) return null;

  const { data } = res;
  const { $enhancedLinkSerializer } = context;

  let rootLink = data.private_logo_link;
  if (isPro) rootLink = data.pro_logo_link;

  const header = {
    rootLink: rootLink?.id ? $enhancedLinkSerializer(rootLink) : null,
    actions: parseActions(data.mainMenu, context),
    footer: parseFooterLinks(
      isPro ? data.pro_footer_links : data.private_footer_links,
      context
    ),
    menu: [
      parseMenu(data, 'private', context),
      parseMenu(data, 'pro', context),
    ],
  };

  if (isPro) header.menu.reverse();

  return header;
}

export default getHeader;
