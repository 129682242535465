import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7fc4ad10 = () => interopDefault(import('./prismic/pages/preview.vue' /* webpackChunkName: "" */))
const _6b32f380 = () => interopDefault(import('../pages/actualites/index.vue' /* webpackChunkName: "pages/actualites/index" */))
const _0c4ac6d1 = () => interopDefault(import('../pages/edito/_uid.vue' /* webpackChunkName: "pages/edito/_uid" */))
const _4d16407d = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _2b14d25f = () => interopDefault(import('../pages/decouvrez-nos-guides/index.vue' /* webpackChunkName: "pages/decouvrez-nos-guides/index" */))
const _c0727420 = () => interopDefault(import('../pages/espace-assure/index.vue' /* webpackChunkName: "pages/espace-assure/index" */))
const _48d1dcf3 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _3ee278ae = () => interopDefault(import('../pages/legacy-browsers.vue' /* webpackChunkName: "pages/legacy-browsers" */))
const _1f1fef96 = () => interopDefault(import('../pages/landing-page/_uid.vue' /* webpackChunkName: "pages/landing-page/_uid" */))
const _d9989690 = () => interopDefault(import('../pages/plan-du-site/index.vue' /* webpackChunkName: "pages/plan-du-site/index" */))
const _e12bb7d0 = () => interopDefault(import('../pages/recherche/index.vue' /* webpackChunkName: "pages/recherche/index" */))
const _3e485fec = () => interopDefault(import('../pages/reclamation/index.vue' /* webpackChunkName: "pages/reclamation/index" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _78b61dcc = () => interopDefault(import('../pages/actualites/_market/index.vue' /* webpackChunkName: "pages/actualites/_market/index" */))
const _9281fae4 = () => interopDefault(import('../pages/_market/guide/_uid.vue' /* webpackChunkName: "pages/_market/guide/_uid" */))
const _d3cfd620 = () => interopDefault(import('../pages/actualites/_market/_article/index.vue' /* webpackChunkName: "pages/actualites/_market/_article/index" */))
const _53ec9ff8 = () => interopDefault(import('../pages/_market/index.vue' /* webpackChunkName: "pages/_market/index" */))
const _1ce208e6 = () => interopDefault(import('../pages/_market/demander-un-devis.vue' /* webpackChunkName: "pages/_market/demander-un-devis" */))
const _11cd3225 = () => interopDefault(import('../pages/_market/guide/index.vue' /* webpackChunkName: "pages/_market/guide/index" */))
const _cc4cbb80 = () => interopDefault(import('../pages/_market/lexique/index.vue' /* webpackChunkName: "pages/_market/lexique/index" */))
const _68b2b9da = () => interopDefault(import('../pages/_market/lexique/_uid.vue' /* webpackChunkName: "pages/_market/lexique/_uid" */))
const _086760fd = () => interopDefault(import('../pages/_market/_product/index.vue' /* webpackChunkName: "pages/_market/_product/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/preview",
    component: _7fc4ad10,
    name: "prismic-preview"
  }, {
    path: "/actualites",
    component: _6b32f380,
    name: "actualites___fr-fr"
  }, {
    path: "/april-courtage",
    component: _0c4ac6d1,
    name: "april-courtage___fr-fr"
  }, {
    path: "/avantage-assures",
    component: _0c4ac6d1,
    name: "avantage-assures___fr-fr"
  }, {
    path: "/contact",
    component: _4d16407d,
    name: "contact___fr-fr"
  }, {
    path: "/decouvrez-nos-guides",
    component: _2b14d25f,
    name: "decouvrez-nos-guides___fr-fr"
  }, {
    path: "/espace-assure",
    component: _c0727420,
    name: "espace-assure___fr-fr"
  }, {
    path: "/faq",
    component: _48d1dcf3,
    name: "faq___fr-fr"
  }, {
    path: "/faq-covid-19",
    component: _0c4ac6d1,
    name: "faq-covid-19___fr-fr"
  }, {
    path: "/legacy-browsers",
    component: _3ee278ae,
    name: "legacy-browsers___fr-fr"
  }, {
    path: "/ma-complementaire-sante-april-formulaire",
    component: _1f1fef96,
    name: "ma-complementaire-sante-april-formulaire___fr-fr"
  }, {
    path: "/mon-formulaire-changer-assurance-de-pret-immobilier",
    component: _1f1fef96,
    name: "mon-formulaire-changer-assurance-de-pret-immobilier___fr-fr"
  }, {
    path: "/parrainage",
    component: _1f1fef96,
    name: "parrainage___fr-fr"
  }, {
    path: "/parrainage-april",
    component: _1f1fef96,
    name: "parrainage-april___fr-fr"
  }, {
    path: "/parrainage-april-filleul",
    component: _1f1fef96,
    name: "parrainage-april-filleul___fr-fr"
  }, {
    path: "/parrainage-recompense",
    component: _0c4ac6d1,
    name: "parrainage-recompense___fr-fr"
  }, {
    path: "/plan-du-site",
    component: _d9989690,
    name: "plan-du-site___fr-fr"
  }, {
    path: "/recherche",
    component: _e12bb7d0,
    name: "recherche___fr-fr"
  }, {
    path: "/reclamation",
    component: _3e485fec,
    name: "reclamation___fr-fr"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___fr-fr"
  }, {
    path: "/actualites/:market",
    component: _78b61dcc,
    name: "actualites-market___fr-fr"
  }, {
    path: "/contact/:uid",
    component: _1f1fef96,
    name: "contact-uid___fr-fr"
  }, {
    path: "/edito/:uid?",
    component: _0c4ac6d1,
    name: "edito-uid___fr-fr"
  }, {
    path: "/guide/:uid?",
    component: _9281fae4,
    name: "guide-uid___fr-fr"
  }, {
    path: "/landing-page/:uid?",
    component: _1f1fef96,
    name: "landing-page-uid___fr-fr"
  }, {
    path: "/actualites/:market/:article",
    component: _d3cfd620,
    name: "actualites-market-article___fr-fr"
  }, {
    path: "/:market",
    component: _53ec9ff8,
    name: "market___fr-fr"
  }, {
    path: "/:market/demander-un-devis",
    component: _1ce208e6,
    name: "market-demander-un-devis___fr-fr"
  }, {
    path: "/:market/guide",
    component: _11cd3225,
    name: "market-guide___fr-fr"
  }, {
    path: "/:market/lexique",
    component: _cc4cbb80,
    name: "market-lexique___fr-fr"
  }, {
    path: "/:market/guide/:uid",
    component: _9281fae4,
    name: "market-guide-uid___fr-fr"
  }, {
    path: "/:market/lexique/:uid",
    component: _68b2b9da,
    name: "market-lexique-uid___fr-fr"
  }, {
    path: "/:market/:product",
    component: _086760fd,
    name: "market-product___fr-fr"
  }, {
    path: "/:market/:product/demander-un-devis",
    component: _1ce208e6,
    name: "market-product-demander-un-devis___fr-fr"
  }, {
    path: "/:market/:submarket/:product",
    component: _086760fd,
    name: "market-submarket-product___fr-fr"
  }, {
    path: "/:market/:submarket/:product/demander-un-devis",
    component: _1ce208e6,
    name: "market-submarket-product-demander-un-devis___fr-fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
