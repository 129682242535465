import {
  LAYOUT_SET_PRO,
  LAYOUT_SET_HEAD,
  LAYOUT_SET_FOOTER_DATA,
  LAYOUT_SET_FOOTER_VARIANT,
  LAYOUT_SET_HEADER_DATA,
  LAYOUT_SET_HEADER_PRO_DATA,
  LAYOUT_SET_HEADER_LITE,
  LAYOUT_SET_CANONICAL_URL,
  LAYOUT_SET_HEADER_CURRENT,
  LAYOUT_SET_BREADCRUMBS,
  LAYOUT_SET_SMART_BUTTON,
  LAYOUT_SET_FIXED_BUTTON,
  LAYOUT_SET_IADVIZE_FLAG,
  LAYOUT_SET_NAV_OPEN_INDEX,
  LAYOUT_SET_TOP_HEADER,
} from './mutation-types';

export const LAYOUT_ACTION_FIXED_BUTTON = 'setHeroWithFixedButton';

export const state = () => ({
  isPro: false,
  topHeader: {
    text: null,
    link: null,
    isHidden: false,
    height: null,
  },
  header: {
    rootLink: null,
    private: null,
    pro: null,
    isLite: false,
  },
  footer: {
    data: null,
    variant: null,
  },
  head: {},
  breadcrumbs: {
    items: [],
  },
  canonicalURL: undefined,
  smartButtonIsActive: false,
  iAdvizeIsActive: false,
  smartButtonContent: null,
  navOpenIndex: null,
  fixedButton: null,
});

export const mutations = {
  // Head
  [LAYOUT_SET_HEAD](mutationState, data) {
    mutationState.head = data;
  },

  // Theme
  [LAYOUT_SET_PRO](mutationState, isPro) {
    mutationState.isPro = isPro;
  },

  // Top Header
  [LAYOUT_SET_TOP_HEADER](mutationState, data) {
    mutationState.topHeader = data;
  },

  // Header
  [LAYOUT_SET_HEADER_DATA](mutationState, data) {
    mutationState.header.rootLink = data?.rootLink;
    mutationState.header.private = data;
    mutationState.header.topHeaderContent = data?.topHeaderContent;
  },
  [LAYOUT_SET_HEADER_PRO_DATA](mutationState, data) {
    mutationState.header.rootLink = data?.rootLink;
    mutationState.header.pro = data;
  },
  [LAYOUT_SET_CANONICAL_URL](mutationState, data) {
    mutationState.canonicalURL = data;
  },
  [LAYOUT_SET_HEADER_LITE](mutationState, data) {
    mutationState.header.isLite = data === true ? {} : data;
  },
  [LAYOUT_SET_HEADER_CURRENT](mutationState, data) {
    if (mutationState.header?.nav === undefined) return;

    const header = mutationState.isPro
      ? mutationState.header.pro
      : mutationState.header.private;

    const currentIndex = header.nav.findIndex((entry) => entry.current);

    if (currentIndex > -1) {
      const currentEntry = header.nav[currentIndex];
      if (currentEntry.uid === data) {
        return;
      }

      header.nav.splice(currentIndex, 1, {
        ...currentEntry,
        current: false,
      });
    }

    if (!data) {
      return;
    }

    const newIndex = header.nav.findIndex((entry) => entry.uid === data);
    if (newIndex === -1) {
      return;
    }

    const newEntry = header.nav[newIndex];
    if (!newEntry) {
      return;
    }

    header.nav.splice(newIndex, 1, {
      ...newEntry,
      current: true,
    });
  },

  // Footer
  [LAYOUT_SET_FOOTER_DATA](mutationState, data) {
    mutationState.footer.data = data;
  },
  [LAYOUT_SET_FOOTER_VARIANT](mutationState, data) {
    mutationState.footer.variant = data;
  },

  // Breadcrumb
  [LAYOUT_SET_BREADCRUMBS](mutationState, data) {
    mutationState.breadcrumbs = data;
  },

  // Smart Button
  [LAYOUT_SET_SMART_BUTTON](mutationState, data) {
    mutationState.smartButtonIsActive = !!data;
    mutationState.smartButtonContent = data;
  },

  // Fixed Button
  [LAYOUT_SET_FIXED_BUTTON](mutationState, data) {
    mutationState.fixedButton = data;
  },

  // iAdvize Flag
  [LAYOUT_SET_IADVIZE_FLAG](mutationState, data) {
    mutationState.iAdvizeIsActive = !!data;
  },

  // Nav Open Index Params
  [LAYOUT_SET_NAV_OPEN_INDEX](mutationState, data) {
    mutationState.navOpenIndex = data;
  },
};

export const actions = {
  [LAYOUT_ACTION_FIXED_BUTTON]({ commit }, data) {
    commit(LAYOUT_SET_FIXED_BUTTON, data);
  },
};
