export const LAYOUT_SET_HEAD = 'LAYOUT_SET_HEAD';
export const LAYOUT_SET_FOOTER_DATA = 'LAYOUT_SET_FOOTER_DATA';
export const LAYOUT_SET_FOOTER_VARIANT = 'LAYOUT_SET_FOOTER_VARIANT';
export const LAYOUT_SET_HEADER_DATA = 'LAYOUT_SET_HEADER_DATA';
export const LAYOUT_SET_HEADER_LITE = 'LAYOUT_SET_HEADER_LITE';
export const LAYOUT_SET_CANONICAL_URL = 'LAYOUT_SET_CANONICAL_URL';
export const LAYOUT_SET_HEADER_CURRENT = 'LAYOUT_SET_HEADER_CURRENT';
export const LAYOUT_SET_HEADER_PRO_DATA = 'LAYOUT_SET_HEADER_PRO_DATA';
export const LAYOUT_SET_BREADCRUMBS = 'LAYOUT_SET_BREADCRUMBS';
export const LAYOUT_SET_PRO = 'LAYOUT_SET_PRO';
export const LAYOUT_SET_NAV_OPEN_INDEX = 'LAYOUT_SET_NAV_OPEN_INDEX';
export const TRACKING_SET_DATA = 'TRACKING_SET_DATA';
export const LAYOUT_SET_SMART_BUTTON = 'LAYOUT_SET_SMART_BUTTON';
export const LAYOUT_SET_FIXED_BUTTON = 'LAYOUT_SET_FIXED_BUTTON';
export const LAYOUT_SET_IADVIZE_FLAG = 'LAYOUT_SET_IADVIZE_FLAG';
export const SEARCH_SET_INDICES = 'SEARCH_SET_INDICES';
export const SEARCH_SET_CURRENT_REFINEMENT = 'SEARCH_SET_CURRENT_REFINEMENT';
export const SEARCH_SET_REFINE = 'SEARCH_SET_REFINE';
export const FREESPEE_SET_STATIC_DATA = 'FREESPEE_SET_STATIC_DATA';
export const FREESPEE_SET_DYNAMIC_DATA = 'FREESPEE_SET_DYNAMIC_DATA';
export const FREESPEE_SET_CALLBACKS = 'FREESPEE_SET_CALLBACKS';
export const EKOMI_SET_REVIEWS = 'EKOMI_SET_REVIEWS';
export const EKOMI_SET_ANSWERS = 'EKOMI_SET_ANSWERS';
export const SET_REDIRECTIONS = 'SET_REDIRECTIONS';
export const LAYOUT_SET_TOP_HEADER = 'LAYOUT_SET_TOP_HEADER';
