// Source : https://developer.mozilla.org/fr/docs/Web/API/SubtleCrypto/digest#convertir_un_condens%C3%A9_vers_une_cha%C3%AEne_hexad%C3%A9cimale

const unsafeHashes = [
  'MD2',
  'MD4',
  'MD5',
  'MD6',
  'HAVAL-128',
  'HMAC-MD5',
  'DSA',
  'RIPEMD',
  'RIPEMD-128',
  'RIPEMD-160',
  'HMACRIPEMD160',
  'SHA-1',
];

export default async function (message, hash = 'SHA-256') {
  if (unsafeHashes.includes(hash)) {
    console.warn(`Unsafe hash specfified: ${hash}`);
    return null;
  }

  const msgUint8 = new TextEncoder().encode(message); // encode comme (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest(hash, msgUint8); // fait le condensé
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convertit le buffer en tableau d'octet

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convertit le tableau en chaîne hexadécimale
}
