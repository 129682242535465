import linkResolver from '~/plugins/prismic/link-resolver';

export default (document, route) => {
  let queryString = '';
  const paramsArray = route.query && Object.entries(route.query);
  if (paramsArray.length > 0) {
    queryString = `?${paramsArray.map((param) => param.join('=')).join('&')}`;
  }
  return `${linkResolver(document)}${queryString}`;
};
