
import pageHelper from '../utils/page-helper';
import { marketLinks, productLinks } from '~/utils/fetch-links';

export default {
  data() {
    return {
      slices: null,
    };
  },
  async mounted() {
    const fetchLinksArray = [...marketLinks, ...productLinks];

    const { $prismic } = this.$root.context;
    const document = await $prismic.api.getSingle('404', {
      fetchLinks: fetchLinksArray,
    });
    if (typeof document !== 'undefined') {
      const slices = await pageHelper(document, this.$root.context);
      this.slices = slices;
    }
  },
};
