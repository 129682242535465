import mergeI18n from '~/utils/merge-i18n';
import dataI18n from '~/i18n/messages/fr-fr.contact-faq.json';
import { richText, stripRichTextWrapperTag } from '../../utils/html';

export default (
  { primary },
  pageData,
  { app: { i18n }, $enhancedLinkSerializer }
) => {
  mergeI18n(i18n, dataI18n);
  return {
    title: stripRichTextWrapperTag(primary.find_contact_title),
    description: richText(primary.find_contact_description),
    cta: {
      label: i18n.t('contact.trouver-contact'),
      ...$enhancedLinkSerializer(primary.find_contact_link),
    },
  };
};
