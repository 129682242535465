import { getDocumentType, getSiteType } from '~/utils/get-type';
import getLinkWithParams from '~/utils/get-link-params';
import linkResolver from '~/plugins/prismic/link-resolver';

export default (context, document) => {
  const { req, route, redirect } = context;

  const templateType = document.type;

  // Check if the document is particulier or pro
  const { tags } = document;

  const documentType = getDocumentType(tags);

  // Check if the site is currently pro or particulier
  const currentSite = getSiteType(req, route);

  // Generate propper document URL
  const documentUrl = getLinkWithParams(document, route);

  // Wrong URL is now saved we have to redirect if necessary
  if (currentSite !== documentType) {
    redirect(301, documentUrl);
  }

  if (
    templateType === 'faq_page' ||
    templateType === 'articles_list_page' ||
    templateType === 'articles_list_market'
  ) {
    return documentUrl;
  }

  return linkResolver(document);
};
