
import LazyHydrate from 'vue-lazy-hydration';
import { LAYOUT_SET_TOP_HEADER } from '~/store/mutation-types';

export default {
  components: {
    LazyHydrate,
    AdvantagesPushPerso: () => import('./advantages-push-perso'),
    AdvantagesPush: () => import('./advantages-push'),
    AccordionPush: () => import('./accordion-push'),
    AccordionPushPerso: () => import('./accordion-push-perso'),
    ArticleHead: () => import('./article-head'),
    BannerPushPerso: () => import('./banner-push-perso'),
    BannerPush: () => import('./banner-push'),
    Breadcrumbs: () => import('./breadcrumbs'),
    ContactConfigurator: () => import('./contact-configurator'),
    ContactFaq: () => import('./contact-faq'),
    ContactPush: () => import('./contact-push'),
    CtaList: () => import('./cta-list'),
    DataViz: () => import('./data-viz'),
    DocumentsList: () => import('./documents-list'),
    EditoCarousel: () => import('./edito-carousel'),
    EditoCta: () => import('./edito-cta'),
    EditoRichText: () => import('./edito-rich-text'),
    EditoTable: () => import('./edito-table'),
    FindContactPush: () => import('./find-contact-push'),
    FeaturedBlock: () => import('./featured-block'),
    FeaturedBlockPerso: () => import('./featured-block-perso'),
    FormSimple: () => import('./form-simple'),
    FormStepsSimple: () => import('./form-steps-simple'),
    FormPush: () => import('./form-push'),
    FormStepsPush: () => import('./form-steps-push'),
    HeroPush: () => import('./hero-push'),
    HeroPushPerso: () => import('./hero-push-perso'),
    IframeBlock: () => import('./iframe-block'),
    LegalMentions: () => import('./legal-mentions'),
    MosaicPush: () => import('./mosaic-push'),
    RatingsPush: () => import('./ratings-push'),
    PictoCarousel: () => import('./picto-carousel'),
    QuotePush: () => import('./quote-push'),
    RelatedGuides: () => import('./related-guides'),
    SeoPush: () => import('./seo-push'),
    SocialMedia: () => import('./social-media'),
    YoutubeVideo: () => import('./youtube-video'),
    WebCallbackStandalone: () => import('./web-callback-standalone'),
    AnswersPush: () => import('./answers-push'),
    BuyapowaPush: () => import('./buyapowa-push'),
    HookPush: () => import('./hook-push'),
    HookPushV2: () => import('./hook-push-v2'),
    AnchorList: () => import('./anchor-list'),
    Calculator: () => import('./calculator'),
    HighlightV2: () => import('./highlight-v2'),
    EditoImageV2: () => import('./edito-image-v2'),
    Quote: () => import('./quote'),
    HeroGeneric: () => import('./hero-generic'),
    HookPushV2Simple: () => import('./hook-push-v2-simple'),
  },
  props: {
    slices: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    attrs: function () {
      return this.slices.map((index) => ({
        [index === 0 ? 'when-idle' : 'when-visible']: true,
      }));
    },
    sliceTopHeader: function () {
      return this.slices?.find((slice) => slice.slice_type === 'top_header');
    },
    topHeaderHeight: function () {
      return this.$store.state.layout.topHeader?.height || 0;
    },
    style: function () {
      return {
        '--top-header-height': `${this.topHeaderHeight}px`,
      };
    },
  },
  mounted: function () {
    if (this.sliceTopHeader) {
      this.$store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, {
        ...this.sliceTopHeader.data,
        isHidden: false,
      });
    } else {
      this.$store.commit(`layout/${LAYOUT_SET_TOP_HEADER}`, null);
    }
  },
  methods: {
    onFormSuccess() {
      this.$emit('formSuccess');
    },
  },
};
