import {
  SEARCH_SET_INDICES,
  SEARCH_SET_CURRENT_REFINEMENT,
  SEARCH_SET_REFINE,
} from './mutation-types';

export const state = () => ({
  indices: [],
  currentRefinement: '',
  refine: () => true,
});

export const mutations = {
  // SEARCH
  [SEARCH_SET_INDICES](mutationState, data) {
    mutationState.indices = data;
  },

  [SEARCH_SET_CURRENT_REFINEMENT](mutationState, data) {
    mutationState.currentRefinement = data;
  },

  [SEARCH_SET_REFINE](mutationState, data) {
    mutationState.refine = data;
  },
};
