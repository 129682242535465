import { getRating } from '~/utils/ekomi';

/**
 * @typedef {Object } ContactData
 * @property {string} contact_phone_label
 * @property {string} contact_phone_number
 * @property {string} contact_inline_description
 * @property {string} contact_mention
 *
 * @typedef {Object} EkomiRating
 * @property {number} review_count
 * @property {number} average_rating
 * @property {Record<string, number>} rating_count
 *
 *
 * @typedef {Object} ContactBannerData
 * @property {{ label: string, phone: string, description: string, mention: string }} contact
 * @property {{ rating: string, totalRatings: number }} ratings
 *
 * @typedef {Object} I18n
 * @property {string} locale
 *
 * @param {ContactData} contact – page contact information
 * @param {EkomiRating} ratings – eKomi ratings data
 * @param {I18n} i18n – i18n data
 *
 * @return {ContactBannerData} – adapter header lite data
 */
export const headerLiteAdapter = (contact, ratings, i18n) => {
  return {
    contact: contact && {
      label: contact.contact_phone_label,
      phone: contact.contact_phone_number,
      description: contact.contact_inline_description,
      mention: contact.contact_mention,
    },
    ratings: ratings && {
      rating: getRating(ratings.average_rating ?? 0, i18n),
      totalRatings: ratings.review_count ?? 0,
    },
  };
};

export default headerLiteAdapter;
