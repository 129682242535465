export default ({ $cookies, route }) => {
  const CMPID_COOKIE_LABEL = 'cmpid';
  const REF_COOKIE_LABEL = '__april_ref';

  const { cmpid } = route.query;
  const options = {
    domain: '.april.fr',
    sameSite: 'none',
    secure: true,
    path: '/',
  };

  const shouldUpdateCookie = (cookie) => {
    const cookieValue = $cookies.get(cookie);
    const isAprilReferrer = document.referrer.includes(options.domain);
    const shouldUpdate = !isAprilReferrer || !cookieValue;

    return shouldUpdate;
  };

  const cmpidCookie = shouldUpdateCookie(CMPID_COOKIE_LABEL)
    ? cmpid
    : $cookies.get(CMPID_COOKIE_LABEL);

  const refCookie = shouldUpdateCookie(REF_COOKIE_LABEL)
    ? encodeURIComponent(document.referrer)
    : $cookies.get(REF_COOKIE_LABEL);

  $cookies.set(CMPID_COOKIE_LABEL, cmpidCookie, options);
  $cookies.set(REF_COOKIE_LABEL, refCookie, options);

  $cookies.set(
    '__april_lan',
    encodeURIComponent(document.location.href),
    options
  );
};
