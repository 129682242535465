const formatPrice = (price) => {
  let numberSplit = price.toString().split('.');
  let whole = numberSplit[0].split('').reverse();
  let decimal = numberSplit[1] ? numberSplit[1].slice(0, 2) : null;
  decimal = decimal && decimal.length === 1 ? (decimal += '0') : decimal;
  for (let i = 3; i < whole.length; i += 3) {
    whole.splice(i, 0, ' ');
  }
  whole = whole.reverse().join('');
  const optionalDecimal = decimal ? `,${decimal}` : '';

  return `${whole}${optionalDecimal} €`;
};

export default function (
  price,
  priceFrequency,
  legalReference,
  i18n,
  options = {}
) {
  const { twoLines, bold } = options;
  const optionalFrequency = priceFrequency ? ` ${priceFrequency}` : '';
  const optionalLegalRef = legalReference
    ? ` <sup>${legalReference}</sup>`
    : '';

  return `${i18n.t('product.a-partir')}${twoLines ? '<br/> ' : ' '}<span>${
    bold ? '<b>' : ''
  }${formatPrice(price)}${
    bold ? '</b>' : ''
  }${optionalFrequency}${optionalLegalRef}</span>`;
}
