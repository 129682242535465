import pageHelper from '~/utils/page-helper';
import { Pro as featuredBlockAdapter } from '~/components/featured-block/adapter';
import {
  TRACKING_SET_DATA,
  LAYOUT_SET_BREADCRUMBS,
} from '~/store/mutation-types';
import {
  routerLinks,
  marketLinks,
  guideLinks,
  articleLinks,
  productLinks,
  masterpageLinks,
  landingpageLinks,
  advantageLinks,
  bannerLinks,
  contactLinks,
  ratingsLinks,
  accordionLinks,
} from '~/utils/fetch-links';
import setUrl from '~/utils/set-url';
import { getEkomiData } from '~/utils/ekomi';

const commonFetchLinks = [
  ...routerLinks,
  ...marketLinks,
  ...guideLinks,
  ...articleLinks,
  ...productLinks,
  ...masterpageLinks,
  ...landingpageLinks,
  ...advantageLinks,
  ...ratingsLinks,
];

const fetchLinksList = {
  default: [],
  home: [...commonFetchLinks, ...bannerLinks, ...accordionLinks],
  staticEdito: [...commonFetchLinks, ...contactLinks],
};

export const isProRoute = (route) =>
  route.name && route.name.indexOf('pro__') !== -1;

export const getHomepageLabel = (route) => {
  if (isProRoute(route)) {
    return 'breadcrumbs.home-pro';
  }

  return 'breadcrumbs.home';
};

export const getContextualisedPageData = async (context, typeBase) => {
  const isPro = isProRoute(context.route);

  const adapters = {};
  if (isPro && typeBase === 'homepage') {
    adapters.featured_block = featuredBlockAdapter;
  }

  return getSinglePageData(
    context,
    isPro ? `${typeBase}_pro` : typeBase,
    adapters
  );
};

export const getSinglePageData = async (context, type, adapters = {}) => {
  const { $prismic, store, app, route } = context;

  let fetchLinksArray;

  switch (type) {
    case 'homepage':
    case 'homepage_pro':
      fetchLinksArray = fetchLinksList.home;
      break;
    default:
      fetchLinksArray = fetchLinksList.default;
  }

  const document = await $prismic.api.getSingle(type, {
    fetchLinks: fetchLinksArray,
  });

  if (typeof document === 'undefined') {
    return undefined;
  }

  if (type === 'homepage') {
    await getEkomiData(null, 'reviews', context);
  }

  const url = setUrl(context, document);

  store.commit(`tracking/${TRACKING_SET_DATA}`, {
    page_type: document.type,
  });

  store.commit(`layout/${LAYOUT_SET_BREADCRUMBS}`, [
    {
      label: app.i18n.t(getHomepageLabel(route)),
      cta: {
        href: '/',
      },
    },
    {
      label:
        document.data?.breadcrumb_title || app.i18n.t('breadcrumbs.default'),
    },
  ]);

  const slices = await pageHelper(document, context, adapters, {
    withBreadcrumb: type === 'clientpage' || type === 'clientpage_pro',
  });

  return {
    url,
    document,
    slices,
  };
};

export const getArticlesSlice = (
  allArticles,
  marketTitle,
  { app: { i18n } }
) => {
  return {
    slice_type: 'edito_carousel',
    slice_label: null,
    items: allArticles.results.map((result) => ({
      edito_carousel_card: { ...result, link_type: 'Document' },
    })),
    primary: {
      edito_carousel_active: false,
      edito_carousel_featured: false,
      edito_carousel_market: null,
      edito_carousel_type: false,
      edito_carousel_title: [
        {
          type: 'paragraph',
          text: i18n.t('article.all-articles-market', {
            market: marketTitle,
          }),
          spans: [],
        },
      ],
      edito_carousel_link: {},
    },
  };
};

export const getGuidesListSlice = (guides) => {
  const allGuides = guides.sort((a, b) => {
    let da = new Date(a.last_publication_date),
      db = new Date(b.last_publication_date);
    return da - db;
  });

  return {
    slice_type: 'edito_carousel',
    slice_label: null,
    items: allGuides.map((result) => ({
      edito_carousel_card: { ...result, link_type: 'Document' },
    })),
    primary: {
      edito_carousel_active: false,
      edito_carousel_featured: false,
      edito_carousel_type: false,
    },
  };
};

export const getGuidesEditoSlice = (document) => {
  if (typeof document === 'undefined') {
    return undefined;
  }
  const { title, description } = document.data;

  return {
    slice_type: 'article_head',
    primary: {
      title,
      description,
    },
  };
};

export const getEditoPageData = (document) => {
  if (typeof document === 'undefined') {
    return undefined;
  }

  const { date, reading_time, title, description, summary } = document.data;
  const articleHead = {
    slice_type: 'article_head',
    primary: {
      date,
      reading_time,
      title,
      description,
      summary,
    },
  };

  const heroIndex = document.data.body.findIndex(
    (el) => el.slice_type === 'hero_push'
  );

  if (!document.data.body.some((el) => el.slice_type === 'article_head')) {
    document.data.body.splice(heroIndex + 1, 0, articleHead);
  }
  return document;
};
