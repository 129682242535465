import Vue from 'vue';
import { asText, stripHTMLTags } from '~/utils/html';
import getHashDigest from '~/utils/get-hash-digest';
import getAgeFromDate from '~/utils/get-age-from-date';
import mergeI18n from '~/utils/merge-i18n';
import dataI18n from '~/i18n/messages/fr-fr.tracking.json';

const fallbackString = 'non renseigné';

const getContext = (context) => {
  const route = context.route || context.$route;
  const store = context.store || context.$store;

  mergeI18n(context.app?.i18n || context.$i18n, dataI18n);
  const i18n = context.app?.i18n || context.$i18n;

  const { $gtm } = context;

  return { route, store, $gtm, i18n };
};

export const pushAlgoliaClick = (
  { indexName, hitObjectIds, hitQueryId, hitPosition },
  $gtm
) => {
  $gtm.push({
    event: 'evenement_web',
    event_type: 'algolia',
    algolia_index_name: indexName || fallbackString,
    algolia_hit_object_ids: hitObjectIds || fallbackString,
    algolia_hit_query_id: hitQueryId || fallbackString,
    algolia_hit_positions: hitPosition || fallbackString,
  });
};

export const pushCtaClick = async (
  context,
  {
    label,
    module,
    lien_cta,
    email = null,
    birthday = null,
    euroAmount = null,
    marketTarget,
    type = null,
  },
  el
) => {
  const { $gtm, store, i18n } = getContext(context);
  let eventLabel = label;
  let eventAction = 'Clic CTA';

  if (module === 'faq-push' && el.getAttribute('aria-expanded') !== 'true') {
    return;
  } else if (module === 'product-card') {
    eventLabel = `${label} ${i18n.t('tracking.product-devis')}`;
  } else if (module === 'form-block-submit') {
    eventLabel = i18n.t('cta.demander-devis');
    eventAction = 'Conversion';
  } else if (module === 'web-callback') {
    eventLabel = i18n.t('freespee.callback.cta_button');
    eventAction = 'Conversion';
  } else if (module === 'form_steps_update') {
    eventLabel = stripHTMLTags(label);
    eventAction = 'Atteinte étape';
  } else if (module === 'form_steps') {
    eventLabel = 'Validation formulaire étapes multiples';
    eventAction = 'Conversion';
  } else if (module === 'cross-sell') {
    eventLabel = 'Cross Sell vers ' + label;
    eventAction = 'Clic CTA';
  }

  const { market, product, page_type } = store.state.tracking.data;
  const categoryMarket = market ? ` ${market}` : '';
  const data = {};

  if (type === 'parrainage') {
    data.event = 'evenement_web';
    data.event_type = 'action_internaute';
    data.marche = marketTarget || fallbackString;
    data.page_type = page_type || fallbackString;
    data.event_categorie = `${
      marketTarget ? `${page_type} ${marketTarget}` : page_type
    }`;
    data.event_action = 'Conversion';
    data.event_libelle = eventLabel;
    data.nom_module = module;
  } else {
    data.event = 'evenement_web';
    data.event_type = 'action_internaute';
    data.event_action = eventAction;
    data.event_libelle = eventLabel;
    data.marche = market || fallbackString;
    data.produit = product || fallbackString;
    data.page_type = page_type || fallbackString;
    data.event_categorie = `${page_type || fallbackString}${categoryMarket}`;
    data.nom_module = module;
    data.page_virtuelle = null;
  }

  if (email) {
    const emailHash = await getHashDigest(email.toLowerCase());
    emailHash && (data.cle_adloop = emailHash);
  }

  if (birthday) data.age = getAgeFromDate(birthday);

  // Directed to external links
  if (lien_cta) data.lien_cta = lien_cta;

  if (euroAmount) data.montant_total_prets = euroAmount;

  if (module === 'form_steps_update') {
    data.event_categorie = 'Formulaire étapes multiples';
    data.nom_module = 'form_steps';
  }

  if (module === 'form_steps') {
    data.event_categorie = 'Formulaire étapes multiples';
  }

  $gtm.push(data);
};

export const pushPageView = (context) => {
  const { $gtm, store } = getContext(context);
  const { market, product, page_type } = store.state.tracking.data;

  $gtm.push({
    event: 'evenement_web',
    event_type: 'page_vue',
    marche: market || fallbackString,
    produit: product ? asText(product) : fallbackString,
    page_type: page_type || fallbackString,
    page_virtuelle: null,
  });
};

export const pushWebCallback = (context) => {
  const { $gtm, store } = getContext(context);
  const { market, product, page_type } = store.state.tracking.data;
  const categoryMarket = market ? ` ${market}` : '';

  $gtm.push({
    event: 'evenement_web',
    event_type: 'action_internaute',
    marche: market || fallbackString,
    produit: product ? asText(product) : fallbackString,
    page_type: page_type || fallbackString,
    event_categorie: `${page_type || fallbackString}${categoryMarket}`,
    event_action: 'Conversion',
    event_libelle: 'Web CallBack - Demande de rappel',
    nom_module: 'Web CallBack',
  });
};

export const pushHookEvent = (context, data, type) => {
  const { $gtm, store } = getContext(context);
  const { market, page_type } = store.state.tracking.data;
  const categoryMarket = market ? ` ${market}` : '';

  const event = {
    event: 'evenement_web',
    event_type: 'action_internaute',
    marche: market || fallbackString,
    page_type: page_type || fallbackString,
    event_categorie: `${page_type || fallbackString}${categoryMarket}`,
    event_action: 'Clic CTA',
  };

  if (type === 'sante') {
    event.jauge_soins_courants = data.soins_courants;
    event.jauge_hospitalisation = data.hospitalisation;
    event.jauge_optique = data.optique;
    event.jauge_dentaire = data.dentaire;
    event.jauge_aides_auditives = data.aides_auditives;
    event.event_libelle = 'Hameçon Parcours Santé';
    event.nom_module = 'Hameçon Parcours Santé';
  } else if (type === 'emprunteur') {
    event.type_bien = data.project_object;
    event.type_projet = data.project_nature;
    event.avancement_projet = data.project_duration;
    event.event_libelle = 'Hameçon Parcours Emprunteur';
    event.nom_module = 'Hameçon Parcours Emprunteur';
  }

  $gtm.push(event);
};

export const pushHookV2Event = (context, type, ctaLabel) => {
  const { $gtm, store } = getContext(context);
  const { market, page_type } = store.state.tracking.data;
  const categoryMarket = market ? ` ${market}` : '';

  const event = {
    event: 'evenement_web',
    event_type: 'action_internaute',
    marche: market || fallbackString,
    page_type: page_type || fallbackString,
    event_categorie: `${page_type || fallbackString}${categoryMarket}`,
    event_action: 'Clic CTA',
    event_libelle: ctaLabel,
    nom_module:
      type === 'sante'
        ? 'Hameçon Parcours Santé'
        : 'Hameçon Parcours Emprunteur',
  };

  $gtm.push(event);
};

export const pushTopHeader = (context, type) => {
  const { $gtm, store } = getContext(context);
  const { market, page_type } = store.state.tracking.data;
  const categoryMarket = market ? ` ${market}` : '';

  const EVENTS = {
    view: {
      label: 'Affichage Top Header',
      action: 'Visibilité élément',
    },
    close: {
      label: 'Fermer - Top Header',
      action: 'Clic CTA',
    },
    link: {
      label: 'Lien - Top Header',
      action: 'Clic CTA',
    },
  };

  $gtm.push({
    event: 'evenement_web',
    marche: market || fallbackString,
    page_type: page_type || fallbackString,
    event_categorie: `${page_type || fallbackString}${categoryMarket}`,
    event_action: EVENTS[type].action,
    event_libelle: EVENTS[type].label,
    nom_module: 'Top Header',
  });
};

export default (context) => {
  context.$gtm.init(context.$config.gtm_id);
  pushPageView(context);

  function attachEvent(el, binding) {
    const { arg, value } = binding;
    if (!arg || !value) return;

    // remove existing listener before attaching new one
    el.removeEventListener(arg, el.event, true);

    let fn = () => pushCtaClick(context, value, el);

    // store the event handle function reference to be used in the unbind callback
    el.event = fn;
    // set useCapture to true to ensure the directive listener triggers first and to avoid bubbling
    el.addEventListener(arg, fn, true);
  }

  Vue.directive('track', {
    // bind only executes once
    bind: attachEvent,
    // update event listener when component is updated, this is necessary to support dynamic value,
    // without it, only the initial value will be registered and any changes won't be reflected.
    componentUpdated: attachEvent,
    unbind: (el, { arg }) => {
      if (!arg || !el.event) return;
      el.removeEventListener(arg, el.event, true);
    },
  });
};
