import getContact from '@/utils/adapters/contact';

export default (data) => {
  const contact = getContact(data.data, true);

  if (!contact) {
    return null;
  }

  return contact;
};
