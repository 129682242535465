export default {
  mounted() {
    this.handleFreespeePageTracking();
  },
  methods: {
    handleFreespeePageTracking() {
      if (window.__fs_dncs_instance) {
        window.__fs_dncs_instance.trackPage(window.location.href);
      }
    },
  },
};
