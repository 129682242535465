import { EKOMI_SET_REVIEWS, EKOMI_SET_ANSWERS } from './mutation-types';

export const state = () => ({
  reviews: {},
  answers: {},
});

export const mutations = {
  [EKOMI_SET_REVIEWS](mutationState, data) {
    const { market, reviews } = data;
    mutationState.reviews[market] = reviews;
  },

  [EKOMI_SET_ANSWERS](mutationState, data) {
    const { market, answers } = data;
    mutationState.answers[market] = answers;
  },
};
