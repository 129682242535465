import {
  LAYOUT_SET_HEAD,
  LAYOUT_SET_SMART_BUTTON,
} from '~/store/mutation-types';
import smartButtonAdapter from '~/utils/adapters/smartButton';
import filterNullOrUndefined from './filterNullOrUndefined';

export const getSlices = (
  data,
  context,
  documentType,
  overrideAdapters = {}
) => {
  let seoLevel = 1;
  return new Promise((resolve, reject) => {
    const promises = data.body.map(async (item, index) => {
      if (item.slice_type === 'seo_separator') {
        seoLevel++;
      }
      if (item.slice_type === 'breadcrumbs') {
        return {
          slice_type: item.slice_type,
          data: {},
        };
      }
      let adapter;
      if (item.slice_type in overrideAdapters) {
        adapter = overrideAdapters[item.slice_type];
      } else if (item.slice_type !== 'seo_separator') {
        const componentAdapter = await import(
          `../components/${item.slice_type.replace(/_/g, '-')}/adapter.js`
        );
        if (componentAdapter) {
          adapter = componentAdapter.default;
        }
      }
      return {
        slice_type: item.slice_type,
        data: adapter
          ? await adapter(item, data, context, {
              seoLevel,
              documentType,
              sliceIndex: index,
            })
          : undefined,
      };
    });
    Promise.all(promises).then(resolve).catch(reject);
  });
};

export const setHead = (data, store) => {
  const headObject = {
    title: data?.page_title,
    description: data?.page_description,
    image: data?.page_image?.url,
    nositemap: data?.seo_sitemap,
    nofollow: data?.seo_nofollow,
    noindex: data?.seo_noindex,
    canonicalUrl: data?.canonical_url,
  };

  store.commit(`layout/${LAYOUT_SET_HEAD}`, filterNullOrUndefined(headObject));
};

export const setSmartButton = async (context, data, store) => {
  const smartButton = await smartButtonAdapter(data, context);
  store.commit(`layout/${LAYOUT_SET_SMART_BUTTON}`, smartButton);
};

export default function (
  document,
  context,
  overrideAdapters = {},
  options = {}
) {
  const { store } = context;

  if (typeof document === 'undefined') {
    throw Error({ statusCode: 404 });
  }

  const { type: documentType } = document;
  const { data } = document;

  const dataDeepCopy = JSON.parse(JSON.stringify(data));

  if (options.withBreadcrumb) {
    const initialIndex =
      dataDeepCopy.body[0].slice_type === 'top_header' ? 1 : 0;

    const firstSliceIsHero = [
      'hero_push',
      'hero_push_perso',
      'hook_push',
      'hook_push_v2',
      'hero_generic',
    ].includes(dataDeepCopy.body[initialIndex].slice_type);
    const breadcrumbsIndex = firstSliceIsHero ? initialIndex + 1 : initialIndex;

    dataDeepCopy.body.splice(breadcrumbsIndex, 0, {
      slice_type: 'breadcrumbs',
    });
  }

  if (options.relatedGuides) {
    const { id, uid } = document;
    dataDeepCopy.id = id;
    dataDeepCopy.uid = uid;
  }

  setHead(data, store);
  setSmartButton(context, data, store);

  return getSlices(dataDeepCopy, context, documentType, overrideAdapters);
}
